<template>
  <div class="">
    
  </div>
</template>

<script>
export default {
  name: 'Home',
  mounted() {
    // window.location.href = 'https://www.souvieshop.com';
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
