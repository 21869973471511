<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  mounted() {
  }
}
</script>

<style>
@import '/font-awesome/css/all.css';
@import '/bootstrap/css/bootstrap.min.css';
body {
  /* background: url("/img/background2.png"); */
  background-color: #fff;
}
</style>
